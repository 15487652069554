import { memoize } from 'lodash-es';

import { useDebounce } from '../../../hooks/useDebounce';
import { getEmailAvailability } from '../../../Pages/lendingApiFetch';

const memoizedEmailCheck = memoize(getEmailAvailability);

export function useDebounceEmailAvailability() {
  return useDebounce(memoizedEmailCheck);
}
