import { createContext, useContext } from 'react';

import {
  GetLoanDocumentsRequiredIwocapayResponse,
  GetPayLinkResponse,
} from '@iwoca/lapi-client/edge';
import { RouteObject, useRoutes } from 'react-router-dom';

import { Checkout } from './Checkout';
import { SecurityChecks } from './components/SecurityChecks/SecurityChecks';
import { checkRequirementVisible } from './helpers/requirements';
import { useCurrentPayLink } from './hooks/useCurrentPayLink';
import { useNavigateToNextRequirement } from './hooks/useNavigateToNextRequirement';
import { AddPaymentMethod } from './requirements/AddPaymentMethod/AddPaymentMethod';
import { DelayedPaylinkComplete } from './requirements/DelayedPaylinkComplete/DelayedPaylinkComplete';
import { GiveUsACall } from './requirements/GiveUsACall/GiveUsACall';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { isGiveUsACallVisible } from './requirements/GiveUsACall/visible';
import { IDCheck } from './requirements/IDCheck/IDCheck';
import { MajorShareholders } from './requirements/MajorShareholders/MajorShareholders';
import { Offer } from './requirements/Offer/Offer';
import { PersonalGuarantee } from './requirements/PersonalGuarantee/PersonalGuarantee';
import { isPersonalGuaranteeVisible } from './requirements/PersonalGuarantee/visible';
import { SecurityChecksSuccess } from './requirements/SecurityChecksSuccess/SecurityChecksSuccess';
import { TGetFundingRequirementsResponse } from '../../api/lending/edge';
import {
  useGetFundingRequirement,
  useGetLoanDocumentsRequiredIwocapay,
} from '../../api/lending/lapiHooks';
import { useOnMount } from '../../hooks/useOnMount';

export const CHECKOUT_BASE_PATH = '/pay/checkout';
export const SECURITY_CHECKS_BASE_PATH = '/pay/security-checks';

export type TVisibilityFunction = ({
  payLink,
  fundingRequirement,
  loanDocumentsRequiredIwocapay,
}: {
  payLink?: GetPayLinkResponse;
  fundingRequirement: TGetFundingRequirementsResponse['data'];
  loanDocumentsRequiredIwocapay: GetLoanDocumentsRequiredIwocapayResponse['data'];
}) => boolean;
export type RequirementsRouteConfig = RouteObject & {
  name: string;
  visible?: TVisibilityFunction;
  path: string;
};

const GENERIC_ROUTES: Array<RequirementsRouteConfig> = [
  {
    name: 'Give us a call',
    path: '/give-us-a-call',
    element: <GiveUsACall />,
    visible: isGiveUsACallVisible,
  },
];

const SECURITY_CHECK_ROUTES: Array<RequirementsRouteConfig> = [
  {
    name: 'ID check',
    path: '/id-check',
    element: <IDCheck />,
    visible: ({ fundingRequirement }) =>
      checkRequirementVisible(fundingRequirement, 'onfido_check'),
  },
  {
    name: 'Personal guarantee',
    path: '/personal-guarantee',
    element: <PersonalGuarantee />,
    visible: isPersonalGuaranteeVisible,
  },
  {
    name: 'add payment method',
    path: '/add-payment-method',
    element: <AddPaymentMethod />,
    visible: ({ fundingRequirement }) =>
      checkRequirementVisible(fundingRequirement, 'add_default_payment_method'),
  },
  {
    name: 'Major shareholders',
    path: '/major-shareholders',
    element: <MajorShareholders />,
    visible: ({ fundingRequirement }) =>
      checkRequirementVisible(fundingRequirement, 'major_shareholders_check'),
  },
];

const COMPLETED_SECURITY_CHECK_ROUTES: Array<RequirementsRouteConfig> = [
  {
    name: 'Completed',
    path: '/completed',
    element: <SecurityChecksSuccess />,
  },
];

const CHECKOUT_ROUTES: Array<RequirementsRouteConfig> = [
  {
    name: 'Offer',
    path: '/offer',
    element: <Offer />,
  },
];

const CAPTURED_PAYLINK_ROUTES: Array<RequirementsRouteConfig> = [
  {
    name: 'Delayed paylink complete',
    path: '/offer/complete',
    element: <DelayedPaylinkComplete />,
  },
];

export const DEFAULT_ROUTE_CONFIG = [
  ...SECURITY_CHECK_ROUTES,
  ...GENERIC_ROUTES,
  ...CHECKOUT_ROUTES,
];

export const SECURITY_CHECK_ROUTE_CONFIG = [
  ...SECURITY_CHECK_ROUTES,
  ...COMPLETED_SECURITY_CHECK_ROUTES,
];

const EntryRoute = () => {
  const { goToNextRequirement } = useNavigateToNextRequirement();
  useOnMount(goToNextRequirement);

  return null;
};

const ENTRY_ROUTE = {
  path: '/',
  element: <EntryRoute />,
};

// Only use this hook within the <Checkout /> component
// It is assumed that the data is already available to determine the available routes
export function useAvailableRoutes() {
  const { payLink } = useCurrentPayLink();
  const { routes } = useContext(RouteContext);

  const { fundingRequirement } = useGetFundingRequirement();
  const { loanDocumentsRequiredIwocapay } =
    useGetLoanDocumentsRequiredIwocapay();

  const availableRoutes = routes
    .filter((route) => {
      if (route.visible === undefined) return true;
      return Boolean(
        route.visible?.({
          payLink,
          fundingRequirement: fundingRequirement!,
          loanDocumentsRequiredIwocapay: loanDocumentsRequiredIwocapay!,
        }),
      );
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .map(({ visible, ...rest }) => rest);

  return availableRoutes;
}

export const RouteContext = createContext<{
  routes: RequirementsRouteConfig[];
  basePath: typeof CHECKOUT_BASE_PATH | typeof SECURITY_CHECKS_BASE_PATH;
}>({
  routes: DEFAULT_ROUTE_CONFIG,
  basePath: CHECKOUT_BASE_PATH,
});

const Routes = ({
  routes,
}: {
  routes: (
    | RequirementsRouteConfig
    | {
        path: string;
        element: JSX.Element;
      }
  )[];
}) => {
  const checkoutRoutes = useRoutes(routes);
  return checkoutRoutes;
};

export const CheckoutRoutes = () => {
  const routes = DEFAULT_ROUTE_CONFIG;

  return (
    <Checkout>
      <RouteContext.Provider value={{ routes, basePath: CHECKOUT_BASE_PATH }}>
        <Routes routes={[...routes, ...CAPTURED_PAYLINK_ROUTES, ENTRY_ROUTE]} />
      </RouteContext.Provider>
    </Checkout>
  );
};

export const SecurityCheckRoutes = () => {
  const routes = SECURITY_CHECK_ROUTE_CONFIG;

  return (
    <SecurityChecks>
      <RouteContext.Provider
        value={{ routes, basePath: SECURITY_CHECKS_BASE_PATH }}
      >
        <Routes routes={[...routes, ENTRY_ROUTE]} />
      </RouteContext.Provider>
    </SecurityChecks>
  );
};
