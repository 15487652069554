import React, { Component } from 'react';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  createBrowserRouter,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  useParams,
} from 'react-router-dom';

import '@iwoca/orion/css/colors.css';
import '@iwoca/orion/css/radii.css';
import '@iwoca/orion/css/shadows.css';
import '@iwoca/orion/css/text.css';
import '@iwoca/orion/css/spacers.css';
import '@iwoca/orion/lib/css/orion.css';
import '@iwoca/login/dist/style.css';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './App.module.css';
import {
  CHECKOUT_BASE_PATH,
  CheckoutRoutes,
  SECURITY_CHECKS_BASE_PATH,
  SecurityCheckRoutes,
} from './Buyer/Checkout/routes';
import { OrderCheckoutPage } from './Buyer/Orders/OrderCheckoutPage/OrderCheckoutPage';
import { GenericPayLinkLanding as GenericPayLinkLandingPage } from './Buyer/PayLinkLanding/GenericPayLinkLanding/GenericPayLinkLanding';
import { PayLinkLanding as PayLinkLandingPage } from './Buyer/PayLinkLanding/PayLinkLanding';
import { PayNowConfirmation } from './Buyer/PayNowConfirmation/PayNowConfirmation';
import { SpendingLimitApproved } from './Buyer/Signup/components/SpendingLimitDecision/SpendingLimitApproved';
import { SIGNUP_BASE_PATH, SignupRoutes } from './Buyer/Signup/routes';
import { SignupLayout } from './Buyer/Signup/SignupLayout';
import { ChooseProduct } from './Buyer/Signup/steps/NewChooseProduct/ChooseProduct';
import { SpendingLimitLanding } from './Buyer/SpendingLimit/SpendingLimitLanding';
import { LoadingScreen } from './components/LoadingScreen/LoadingScreen';
import { useOnMount } from './hooks/useOnMount';
import { GenericError } from './Pages/FailurePages/GenericError';
import { PayLinkNotFound } from './Pages/FailurePages/PayLinkNotFound';
import { XeroPayLinkError } from './Pages/FailurePages/XeroPayLinkError/XeroPayLinkError';
import { PageNotFound } from './Pages/PageNotFound/PageNotFound';
import { routes } from './routing/app.routes';
import { IwQueryClientProvider } from './store/IwQueryClientProvider';
import { Toaster } from './store/IwToast';
import logger from './utils/logger';
import { initialise } from './utils/tracking';

type TFSUserVars = Record<string, number | boolean | string>;
declare global {
  interface Window {
    FS?: {
      setUserVars: (values: TFSUserVars) => void;
      identify: (id: string, values?: TFSUserVars) => void;
    };
  }
}

const AppProviders = () => {
  return (
    <IwQueryClientProvider>
      <ReactQueryDevtools initialIsOpen={false} />
      <Toaster />
      <ErrorBoundary>
        <div className={styles.router}>
          <Outlet />
        </div>
      </ErrorBoundary>
    </IwQueryClientProvider>
  );
};

export const Root = () => {
  return (
    <Routes>
      <Route element={<AppProviders />}>
        {/* shared routes */}
        <Route path="/pay/404/" element={<PageNotFound />} />
        <Route path="/pay/loading/" element={<LoadingScreen />} />

        {/* buyer routes  */}
        <Route path={`${SIGNUP_BASE_PATH}*`} element={<SignupRoutes />} />
        <Route
          path="/pay/choose-product/"
          element={
            <SignupLayout>
              <ChooseProduct />
            </SignupLayout>
          }
        />
        <Route path={`${CHECKOUT_BASE_PATH}/*`} element={<CheckoutRoutes />} />
        <Route
          path={`${SECURITY_CHECKS_BASE_PATH}/*`}
          element={<SecurityCheckRoutes />}
        />
        <Route
          path="/pay/donation/mental-health-uk/"
          element={<PayLinkLandingDemo />}
        />
        <Route
          path="/pay/spending-account/*"
          element={<SpendingLimitRoutes />}
        />
        <Route
          path="/pay/me/:sellerHandle/package/:payLinkPackageId/"
          element={<GenericPayLink />}
        />
        <Route path="/pay/me/:sellerHandle" element={<GenericPayLink />} />
        <Route
          path="/pay/to/:seller/:payLinkId/"
          element={<PayLinkLanding />}
        />
        <Route path="/pay/order/*" element={<OrderRoutes />} />
        <Route
          path="/pay/pay-now/confirmation/"
          element={<PayNowConfirmation />}
        />
        <Route
          path="/pay/pay-now/confirmation/overlay/"
          element={<PayNowConfirmation />}
        />
        <Route path="pay/xero-error/" element={<XeroPayLinkError />} />
      </Route>
    </Routes>
  );
};

const browserRouter = createBrowserRouter([
  ...routes,
  { path: '*', element: <Root /> },
]);

export function App({ router = browserRouter }) {
  useOnMount(() => {
    initialise();
  });

  return <RouterProvider router={router} />;
}

const SpendingLimitRoutes = () => (
  <Routes>
    <Route path=":sellerHandle/" element={<SpendingLimitLanding />} />
    <Route path="approved/" element={<SpendingLimitApproved />} />
  </Routes>
);

const OrderRoutes = () => (
  <Routes>
    <Route path=":orderId/landing/" element={<OrderCheckoutPage />} />
    <Route path=":orderId/" element={<OrderCheckoutPage />} />
  </Routes>
);

const GenericPayLink = () => {
  const { sellerHandle, payLinkPackageId } = useParams();

  return (
    <main className="h-full min-h-screen w-full flex-auto text-white">
      {sellerHandle && (
        <GenericPayLinkLandingPage
          sellerHandle={sellerHandle}
          payLinkPackageId={payLinkPackageId}
        />
      )}
    </main>
  );
};

const PayLinkLanding = () => {
  const { payLinkId } = useParams();

  return (
    <main className="h-full min-h-screen w-full flex-auto text-white">
      {payLinkId ? (
        <PayLinkLandingPage payLinkId={payLinkId} />
      ) : (
        <PayLinkNotFound />
      )}
    </main>
  );
};

const PayLinkLandingDemo = () => {
  const MENTAL_HEALTH_UK_2_POUND_PAY_LINK_ID =
    '6977a0b1-59a9-4ef4-a532-1b3d6fc928dc';
  return (
    <main className="h-full min-h-screen w-full flex-auto text-white">
      <PayLinkLandingPage payLinkId={MENTAL_HEALTH_UK_2_POUND_PAY_LINK_ID} />
    </main>
  );
};

export class ErrorBoundary extends Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  state = { hasError: false };

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({ hasError: true });
    logger.error(error.toString(), { stack: error.stack, ...info });
  }

  render() {
    if (this.state.hasError) {
      return <GenericError errorText="something went wrong." />;
    }
    return this.props.children;
  }
}
