import { useState } from 'react';

import { GetStateResponse, fetchPutState } from '@iwoca/lapi-client/edge';
import { useMutation } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { cloneDeep, set } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import { requestCallbackValidator } from './requestCallbackValidator';
import styles from './SpeakToDirector.module.css';
import { useGetStateByStateKey } from '../../../../api/lending/lapiHooks';
import hero from '../../../../components/GiveUsACall/hero.jpg';
import { Input } from '../../../../components/Input/Input';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import Check from '../../../../components/svg/Check.svg';
import PhoneIcon from '../../../../components/svg/PhoneIconWhite.svg';
import {
  iwocapayTelDisplay,
  iwocapayTelLink,
} from '../../../../constants.json';
import { useStateKey } from '../../../../hooks/useStateKey.hook';
import { Button, SubmitButton } from '../../../components/Button/Button';

export const SpeakToDirector = () => {
  const [speakToDirectorToggle, setSpeakToDirectorToggle] = useState(false);

  return (
    <div className={styles.directorScreen}>
      <h2 className={styles.title}>We need to speak to a director</h2>
      <div className={styles.mainBody}>
        <img src={hero} className={styles.heroImage} alt="" />
        <p className={styles.explanation}>
          <strong className={styles.strongPortion}>
            You need to be a director of the business to complete this
            application. Give us a quick call{' '}
            <span className={styles.desktopHelp}>
              on{' '}
              <a href={iwocapayTelLink} className={styles.phoneLink}>
                {iwocapayTelDisplay}
              </a>
            </span>{' '}
            and we can explain the next steps.
          </strong>
        </p>
      </div>
      <p className={styles.alternativeText}>
        Alternatively, you can enter your number below and we’ll give you a call
        as soon as one of the team is available.
      </p>
      <div className={styles.mobileOptions}>
        <a href={iwocapayTelLink} className={styles.phoneNumberLink}>
          <SubmitButton className={styles.callNow}>
            {iwocapayTelLink}
            <img
              className={styles.phoneIcon}
              src={PhoneIcon}
              alt="Phone Icon"
            />
          </SubmitButton>
        </a>
        {!speakToDirectorToggle && (
          <>
            <p className={styles.mobileOr}>Or</p>
            <Button
              onClick={() => setSpeakToDirectorToggle(!speakToDirectorToggle)}
              className={styles.requestCallbackButton}
            >
              Request callback
            </Button>
          </>
        )}
        {speakToDirectorToggle && (
          <>
            <h3 className={styles.requestCallbackMobile}>
              Request a call back
            </h3>
            <p>
              Enter your contact number below and we’ll give you a call as soon
              as one of the team is available.
            </p>
            <RequestCallBackForm />
          </>
        )}
      </div>
      <div className={styles.desktopOptions}>
        <RequestCallBackForm />
      </div>
    </div>
  );
};

const RequestCallBackForm = () => {
  const [addedNumber, setSuccessAddedNumber] = useState(false);
  const { state, refetchState } = useGetStateByStateKey();
  const { stateKey } = useStateKey();

  const initialValues = {
    callbackPhoneNumber: '',
  };

  const { isPending, mutateAsync } = useMutation({
    mutationFn: async (state: GetStateResponse) => {
      if (!stateKey) {
        throw new Error('No statekey provided');
      }

      await fetchPutState({ stateKey, body: state });

      await refetchState();
    },
  });

  const addPhoneNumberToCustomer = async (customerPhoneNumber: string) => {
    const newState = cloneDeep(state);
    if (newState?.application?.people) {
      const modifiedApplicant = newState?.application?.people?.filter(
        (people) => {
          return people.roles?.includes('applicant');
        },
      )[0];

      if (modifiedApplicant) {
        const modifiedApplicantIndex =
          newState.application.people.indexOf(modifiedApplicant);
        const matchingNumbers = modifiedApplicant.phones?.filter(
          (phone) => phone.number === customerPhoneNumber,
        );

        const newPhones: typeof modifiedApplicant.phones = [
          {
            uid: uuidv4(),
            type: 'primary',
            number: customerPhoneNumber,
          },
        ];

        if (!modifiedApplicant.phones) {
          modifiedApplicant.phones = newPhones;

          set(
            newState,
            `application.people[${modifiedApplicantIndex}]`,
            modifiedApplicant,
          );
        } else if (matchingNumbers?.length === 0) {
          modifiedApplicant.phones = modifiedApplicant.phones.concat(newPhones);

          set(
            newState,
            `application.people[${modifiedApplicantIndex}]`,
            modifiedApplicant,
          );
        }

        await mutateAsync({ data: newState });
      }
    }
  };

  const handleSubmit = async (values: typeof initialValues) => {
    await addPhoneNumberToCustomer(values.callbackPhoneNumber);
    setSuccessAddedNumber(true);
  };

  return (
    <>
      <p className={styles.dontWorry}>
        Don’t worry – it won’t be shared with anyone else.
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
        enableReinitialize={true}
        validationSchema={requestCallbackValidator}
      >
        <Form autoComplete="on">
          <Input
            label=""
            labelDescriptionText=""
            name="callbackPhoneNumber"
            className={styles.callbackPhoneInput}
          />
          <SubmitButton
            className={styles.requestCallbackButton}
            disabled={false}
          >
            {!isPending ? 'Request call back' : <LoadingSpinner />}
          </SubmitButton>
        </Form>
      </Formik>
      {addedNumber && (
        <div className={styles.wellBeInTouch}>
          <img src={Check} alt="" className={styles.checkBox} />
          Thanks. We’ll be in touch soon.
        </div>
      )}
    </>
  );
};
