import React from 'react';

import { CheckBox } from '@iwoca/orion';
import cn from 'classnames';
import { set } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import * as Yup from 'yup';

import styles from './EmailInput.module.css';
import { useDebounceEmailAvailability } from './useDebounceEmailAvailability';
import {
  useGetContext,
  useGetProfile,
  useGetStateByStateKey,
} from '../../../api/lending/lapiHooks';
import { HookFormInput } from '../../../components/Input/Input';
import { postLendingApiJson } from '../../../Pages/lendingApiFetch';
import { extractError } from '../../../utils/ReactHookForm';
import { FormValues } from '../../SpendingLimit/components/EmailDetails/EmailDetails';
import { Button } from '../Button/Button';
import { LoginLink } from '../LoginLink/LoginLink';

const EMAIL_FIELD_ID = 'emailAddress';
function EmailInput({
  loggedIn = false,
  isSpendingLimit = false,
}: {
  loggedIn: boolean;
  isSpendingLimit?: boolean;
}) {
  const { formState, trigger } = useFormContext();
  const { touchedFields, errors } = formState;
  const error = extractError(errors[EMAIL_FIELD_ID]);

  const { state, refetchState } = useGetStateByStateKey();
  const { getContext } = useGetContext();
  const { getProfile } = useGetProfile();
  const loggedInCustomerEmailAddress =
    state?.application?.people?.[0].emails?.[0].email;

  const hideLabel = loggedIn || isSpendingLimit;

  return (
    <div>
      <HookFormInput
        label="Create your iwocaPay account"
        labelDescriptionText={
          !loggedIn
            ? 'Enter your email address to create your account or log in.'
            : ''
        }
        type="text"
        name={EMAIL_FIELD_ID}
        placeholder={'Business email address...'}
        onChange={async () => {
          set(formState.touchedFields, EMAIL_FIELD_ID, true);
          await trigger(EMAIL_FIELD_ID);
        }}
        disabled={Boolean(loggedInCustomerEmailAddress)}
        showError={false}
        data-testid="emailSignupInput"
        className={cn(styles.formInput, {
          [styles.formInputHideLabel]: hideLabel,
        })}
      />

      {Boolean(loggedInCustomerEmailAddress) && (
        <p className={styles.logoutText}>
          Not you?{' '}
          <button
            type="button"
            className={styles.logoutButton}
            onClick={async () => {
              await postLendingApiJson({ url: '/api/lending/edge/logout/' });
              await Promise.all([getContext(), getProfile(), refetchState()]);
            }}
          >
            Log out
          </button>
        </p>
      )}

      {touchedFields[EMAIL_FIELD_ID] && errors[EMAIL_FIELD_ID] && (
        <>
          {error === 'EMAIL_REGISTERED_MESSAGE' ? (
            <LoginLink />
          ) : (
            <div className={styles.inputError}>{error}</div>
          )}
        </>
      )}
    </div>
  );
}

const getButtonText = ({
  loggedIn = false,
  isSpendingLimit = false,
}: {
  loggedIn: boolean;
  isSpendingLimit?: boolean;
}) => {
  if (isSpendingLimit && !loggedIn) {
    return 'Create account and continue';
  }
  if (isSpendingLimit && loggedIn) {
    return 'Continue';
  }
  if (!isSpendingLimit && !loggedIn) {
    return 'Create account and check out';
  }
  return 'Finish checkout';
};

export const EmailFormInput = ({
  loggedIn = false,
  isSpendingLimit = false,
}: {
  loggedIn: boolean;
  isSpendingLimit?: boolean;
}) => {
  const { formState, register } = useFormContext();

  const errors = formState.errors;
  const isSubmitting = formState.isSubmitting;

  const EMAIL_FIELD_ID = 'emailAddress';
  const isFirstTimeTouched =
    !formState.touchedFields[EMAIL_FIELD_ID] && !loggedIn;

  const isSubmitDisabled =
    isSubmitting || Object.keys(errors).length !== 0 || isFirstTimeTouched;

  const buttonText = getButtonText({ loggedIn, isSpendingLimit });

  return (
    <>
      <div className={styles.emailInput}>
        <EmailInput loggedIn={loggedIn} isSpendingLimit={isSpendingLimit} />
      </div>
      <Button
        type="submit"
        className={styles.continueButton}
        disabled={isSubmitDisabled}
        loading={isSubmitting}
        data-testid="continueStep0Button"
      >
        {buttonText}
      </Button>
      <div className={styles.emailCheckboxContainer}>
        {!loggedIn && (
          <CheckBox
            {...register('marketingOptIn')}
            id="marketingOptIn"
            label="Sign me up for small business news"
          />
        )}
      </div>
    </>
  );
};
export const useEmailValidation = () => {
  const debounceEmailAvailability = useDebounceEmailAvailability();
  const { state: customerState } = useGetStateByStateKey();
  const loggedInCustomerEmailAddress =
    customerState?.application?.people?.[0].emails?.[0].email;

  const validate = async (values: FormValues) => {
    const errors: { [k: string]: string } = {};

    if (!loggedInCustomerEmailAddress) {
      const isEmailValid = Yup.string()
        .email()
        .required()
        .isValidSync(values.emailAddress);

      if (isEmailValid) {
        let isEmailAvailable: null | boolean = null;
        try {
          isEmailAvailable = (await debounceEmailAvailability(
            values.emailAddress,
          )) as boolean;
        } catch (e: unknown) {}

        if (isEmailAvailable === false) {
          errors.emailAddress = 'EMAIL_REGISTERED_MESSAGE';
        }
      } else {
        errors.emailAddress = 'Please enter a valid email address';
      }
    }

    if (Object.keys(errors).length > 0) {
      return {
        values: {},
        errors,
      };
    }

    return {
      values,
      errors,
    };
  };

  return validate;
};
