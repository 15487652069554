import { useState } from 'react';

import {
  GetApplicationResponse,
  GetFormalOffersResponse,
} from '@iwoca/lapi-client/edge';
import { Button } from '@iwoca/orion';
import { useQueries } from '@tanstack/react-query';
import cn from 'classnames';
import { sum } from 'lodash-es';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

import styles from './ChooseProduct.module.css';
import {
  useGetApplication,
  useGetFormalOffers,
} from '../../../../api/lending/lapiHooks';
import { displayCurrency } from '../../../../utils/DisplayCurrency';
import {
  ExampleRepaymentScheduleOverview,
  getExampleRepaymentScheduleOverview,
} from '../../../../utils/exampleRepaymentSchedule';
import { roundTo } from '../../../../utils/roundTo';
import { trackSelectedOffer } from '../../../../utils/tracking';
import { useCurrentPayLink } from '../../../Checkout/hooks/useCurrentPayLink';
import { CHECKOUT_BASE_PATH } from '../../../Checkout/routes';
import { useTrackViewedOffers } from '../../../hooks/useTrackViewedOffers';
import { PayLink } from '../../../PayLinkLanding/utils/PayLinkLanding.types';
import { getDurationInMonths } from '../../../utils/product';
import { buildQueryString } from '../../../utils/queryParams';

export const ChooseProduct = () => {
  const { formalOffers, loadingFormalOffers } = useGetFormalOffers();
  const { payLink, loadingPayLink } = useCurrentPayLink();
  const { application } = useGetApplication();
  const results = useQueries({
    queries: useGetMultiOfferDrawDownRepaymentScheduleConfig({ formalOffers }),
  });
  const [activeScheduleId, setActiveScheduleId] = useState<string | null>(null);
  const navigate = useNavigate();

  useTrackViewedOffers({
    payLinkId: payLink?.id,
    formalOffers: formalOffers?.formal_offers,
    loadingApis: loadingPayLink || loadingFormalOffers,
  });

  if (
    loadingFormalOffers ||
    results.some(({ isLoading, isPending }) => isLoading || isPending) ||
    !formalOffers?.formal_offers ||
    !application
  )
    return <ChooseProductLoadingPlaceholder />;

  const sellerEnabled12m = hasSellerEnabled12m({ payLink });

  const offers = sellerEnabled12m
    ? formalOffers.formal_offers
    : formalOffers.formal_offers.filter(
        (offer) => offer.duration && getDurationInMonths(offer.duration) === 3,
      );
  const hasMultipleOffers = offers.length > 1;

  const repaymentSchedules = results.map(({ data }) => data!);
  const repaymentSchedule =
    repaymentSchedules?.find(
      (schedule) => schedule?.schedule_id === activeScheduleId,
    ) || null;
  const productData = getProductData({
    offers,
    repaymentSchedules: repaymentSchedules,
    application,
  });

  const getFormattedInterestRate = (duration: number, interestRate: number) => {
    const productPricing = payLink?.product_pricing;
    const productLabel = duration === 12 ? 'TWELVE_MONTHS' : 'THREE_MONTHS';
    const isInterestFree =
      productPricing?.[productLabel]?.representative_interest === 0;

    if (isInterestFree) {
      return 'Interest free';
    }
    const formattedInterestRate = roundTo(interestRate * 100, 2);
    return `${formattedInterestRate}%`;
  };

  const shouldSkipToCheckout = () => {
    return (
      !sellerEnabled12m &&
      payLink?.product_pricing?.THREE_MONTHS.representative_interest === 0
    );
  };

  const skipToCheckout = () => {
    const offer_3m = offers.find((offer) => {
      if (!offer.duration) return false;
      return getDurationInMonths(offer.duration) === 3;
    });
    if (!offer_3m?.offer_id) return;

    const queryString = buildQueryString({ offerId: offer_3m.offer_id }, true);
    navigate(`/pay/checkout/${queryString}`);
  };

  const goToCheckout = ({ offerId }: { offerId: string }) => {
    trackSelectedOffer({
      payLinkId: payLink!.id,
      offerIds: productData.map((offer) => offer.id!),
      offerId,
    });

    const queryString = buildQueryString({ offerId }, true);
    window.location.href = `${CHECKOUT_BASE_PATH}/${queryString}`;
  };

  if (shouldSkipToCheckout()) {
    skipToCheckout();
  }

  return (
    <div className={styles.chooseProduct}>
      <div className={styles.intro}>
        {!sellerEnabled12m ? (
          <>
            <div
              className={cn(styles.chooseProductTitle, styles.approvedTitle)}
            >
              Approved
            </div>
          </>
        ) : hasMultipleOffers ? (
          <>
            <div className={styles.chooseProductTitle}>
              Good news! You have a choice
            </div>
            <p className={styles.chooseProductSubtitle}>
              You have been approved and have 2 options available.
            </p>
            <p className={styles.chooseProductDescription}>
              Choose an option to continue
            </p>
          </>
        ) : (
          <>
            <h1 className={styles.chooseProductTitle}>
              Sorry, we can't approve you for
              <br />
              Pay-in-12 right now
            </h1>
            <p className={styles.chooseProductSubtitle}>
              But you can still pay over 90 days instead.
            </p>
          </>
        )}
      </div>
      <div>
        <table
          className={cn(styles.productTable, {
            [styles.multipleOffers]: hasMultipleOffers,
          })}
        >
          <tbody>
            <tr>
              <th>Monthly repayment</th>
              {productData.map(({ id, monthlyRepayment, originalChoice }) => (
                <td key={id} className={styles.monthlyRepayment}>
                  {originalChoice && sellerEnabled12m && (
                    <div className={styles.choiceIndicator}>
                      Your original choice
                    </div>
                  )}
                  <span>
                    {displayCurrency(monthlyRepayment)}
                    <span className={styles.perMonth}>/mo</span>
                  </span>
                </td>
              ))}
            </tr>
            <tr>
              <th>Duration</th>
              {productData.map(({ id, duration }) => (
                <td key={id}>
                  {duration} months
                  <p className={styles.tableCellSubtitle}>
                    It's free to repay early!
                  </p>
                </td>
              ))}
            </tr>
            <tr>
              <th>Interest rate</th>
              {productData.map(({ duration, interestRate }) => {
                return (
                  <td key={`interestRate_${duration}`}>
                    {getFormattedInterestRate(duration, interestRate)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <th>
                Total repayment
                <p className={styles.tableCellSubtitle}>
                  Reduce this by making early repayments for free
                </p>
              </th>
              {productData.map(({ totalRepayment }) => (
                <td key={totalRepayment}>{displayCurrency(totalRepayment)}</td>
              ))}
            </tr>
          </tbody>
          <tfoot>
            <tr className={styles.tableFooter}>
              {hasMultipleOffers ? <th></th> : null}
              {productData.map(({ id, name, schedule_id }) => {
                const isScheduleVisible = schedule_id === activeScheduleId;

                return (
                  <td key={name}>
                    <Button
                      className={styles.checkoutButton}
                      onClick={() => goToCheckout({ offerId: id! })}
                    >
                      Checkout {hasMultipleOffers ? <br /> : null}
                      with {name}
                    </Button>
                    <Button
                      className={styles.scheduleButton}
                      variant="tertiary"
                      onClick={() => {
                        if (isScheduleVisible) {
                          setActiveScheduleId(null);
                          return;
                        }

                        setActiveScheduleId(schedule_id);
                      }}
                    >
                      {!isScheduleVisible
                        ? 'View schedule +'
                        : 'Hide schedule -'}
                    </Button>
                  </td>
                );
              })}
            </tr>
          </tfoot>
        </table>
      </div>
      {repaymentSchedule !== null && (
        <BreakdownTable repaymentSchedule={repaymentSchedule} />
      )}
    </div>
  );
};

const ChooseProductLoadingPlaceholder = () => {
  // Fill with placeholder data
  const columns = new Array(2).fill(0);
  const rows = new Array(3).fill(0);

  return (
    <div
      className={styles.chooseProduct}
      data-testid="choose-product-loading-skeleton"
    >
      <div className={styles.intro}>
        <>
          <h1 className={styles.chooseProductTitle}>
            <Skeleton height="44px" width="80%" />
          </h1>
          <p className={styles.chooseProductSubtitle}>
            <Skeleton height="28px" />
          </p>
          <p className={styles.chooseProductDescription}>
            <Skeleton height="24px" width="30%" />
          </p>
        </>
      </div>
      <div>
        <table className={styles.productTable}>
          <tbody>
            {rows.map((_, i) => (
              <tr key={i}>
                <th>
                  <Skeleton height="44px" width="60%" />
                </th>
                {columns.map((_, j) => (
                  <td key={`${i}_${j}`}>
                    <Skeleton width="80%" />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className={styles.tableFooter}>
              <th></th>
              {columns.map((_, i) => {
                return (
                  <td key={i}>
                    <Button
                      className={styles.checkoutButton}
                      onClick={() => {}}
                      disabled={true}
                    >
                      <Skeleton width="100%" />
                    </Button>
                  </td>
                );
              })}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

const BreakdownTable = ({
  repaymentSchedule,
}: {
  repaymentSchedule: ExampleRepaymentScheduleOverview;
}) => {
  const tableHeaders = ['Date', 'Invoice amount', 'Interest*', 'Total'];
  const tableData = repaymentSchedule.fullSchedule.map(
    ({ date, principal, interest, total }) => {
      return [
        formatDate(date),
        displayCurrency(principal),
        displayCurrency(interest),
        displayCurrency(total),
      ];
    },
  );

  const invoiceTotal = sum(
    repaymentSchedule.fullSchedule.map(({ principal }) => principal),
  );

  const interestTotal = sum(
    repaymentSchedule.fullSchedule.map(({ interest }) => interest),
  );

  const totalTotal = sum(
    repaymentSchedule.fullSchedule.map(({ total }) => total),
  );

  return (
    <div className={styles.tableWrapper}>
      <table className={styles.breakdownTable}>
        <thead className={styles.tableHeader}>
          <tr>
            {tableHeaders.map((title) => (
              <th className={styles.tableHeaderCell} key={title}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row) => (
            <tr key={row.join('_')}>
              {row.map((data) => (
                <td className={styles.tableCell} key={data}>
                  {data}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot className={styles.breakdownTableFooter}>
          <tr>
            <td className={styles.tableCell}>
              <b>Total</b>
            </td>
            <td className={styles.tableCell}>
              {displayCurrency(invoiceTotal)}
            </td>
            <td className={styles.tableCell}>
              {displayCurrency(interestTotal)}
            </td>
            <td className={styles.tableCell}>{displayCurrency(totalTotal)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

function formatDate(rawDate: string) {
  return rawDate.split('-').reverse().join('.');
}

function getProductData({
  offers,
  repaymentSchedules,
  application,
}: {
  offers: NonNullable<
    NonNullable<GetFormalOffersResponse['data']>['formal_offers']
  >;
  repaymentSchedules: Awaited<
    ReturnType<typeof getExampleRepaymentScheduleOverview>
  >[];
  application: GetApplicationResponse['data'];
}) {
  if (!offers) return [];

  const requestedProduct = application?.requested_products?.[0];
  if (!requestedProduct) return [];

  const applicationDuration = getDurationInMonths(requestedProduct.duration);

  const productOfferData = offers.map(
    ({ offer_id, interest_rates, duration: offerDuration }, offerIndex) => {
      const schedule = repaymentSchedules[offerIndex];
      const duration = getDurationInMonths(offerDuration!);
      const rate = interest_rates?.[0].rate;

      return {
        id: offer_id,
        schedule_id: schedule?.schedule_id,
        name: `pay-in-${duration}`,
        monthlyRepayment: schedule?.exampleMonthlyRepayment,
        interestRate: rate!,
        duration: duration,
        totalRepayment: schedule?.exampleTotalRepayment,
        originalChoice: applicationDuration === duration,
      };
    },
  );

  return productOfferData;
}

function useGetMultiOfferDrawDownRepaymentScheduleConfig({
  formalOffers,
}: {
  formalOffers: NonNullable<GetFormalOffersResponse['data']> | null;
}) {
  const payLink = useCurrentPayLink();
  const offers = formalOffers?.formal_offers;
  if (!offers || !payLink) return [];

  return offers.map((offer) => {
    const { duration: offerDuration } = offer;

    const rate = offer.interest_rates?.[0].rate;
    const duration = getDurationInMonths(offerDuration!) as 3 | 12;

    const productPricing = payLink?.payLink?.product_pricing;
    const productLabel = duration === 12 ? 'TWELVE_MONTHS' : 'THREE_MONTHS';
    const isInterestFree =
      productPricing?.[productLabel]?.representative_interest === 0;

    const representativeInterest = isInterestFree ? 0 : rate!;

    const pricingPromotions =
      payLink.payLink?.product_pricing?.[productLabel]?.promotions!;
    const requestedAmount = payLink.payLink?.amount;

    return {
      queryKey: [
        'fetchGetExampleRepaymentSchedule',
        requestedAmount,
        duration,
        representativeInterest,
        pricingPromotions,
      ],
      queryFn: () =>
        getExampleRepaymentScheduleOverview(
          requestedAmount!,
          representativeInterest,
          pricingPromotions,
          duration,
        ),
      enabled: Boolean(requestedAmount) && Boolean(duration),
    };
  });
}

const hasSellerEnabled12m = ({ payLink }: { payLink?: PayLink }) => {
  if (!payLink?.product_pricing) return false;
  return Object.keys(payLink?.product_pricing).includes('TWELVE_MONTHS');
};
