import { useSellerHandle } from '../hooks/useSellerHandle.hook';
import { GenericError } from '../Pages/FailurePages/GenericError';

export const IsSellerBlocked = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { sellerHandle, error } = useSellerHandle();

  if (error) {
    return (
      <GenericError errorText="looks like there is an issue with your account" />
    );
  }

  if (!sellerHandle) return null;

  return <>{children}</>;
};
