import React from 'react';

export const VerticalSplitLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="grid grid-cols-2 gap-2xl m:grid-cols-1">{children}</div>
  );
};

export const Column = ({ children }: { children: React.ReactNode }) => {
  return <div>{children}</div>;
};
