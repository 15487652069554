import { useEffect, useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import classnames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import { DefaultContent } from './DefaultContent/DefaultContent';
import { ErrorContent } from './ErrorContent/ErrorContent';
import { PaymentAccountPut } from './PaymenAccount.types';
import styles from './PaymentAccount.module.css';
import { fetchGetXeroPaymentAccounts } from '../../../api/lending/edge';
import { buildQueryString } from '../../../Buyer/utils/queryParams';
import { useStateKey } from '../../../hooks/useStateKey.hook';
import { putLendingApiJson } from '../../../Pages/lendingApiFetch';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { TryAgainError } from '../TryAgainError/TryAgainError';
import { useXero } from '../Xero.context';
import { contextLoadedSuccessfully } from '../Xero.helpers';
import sellerXeroStyles from '../Xero.module.css';
import { DropdownOption } from '../Xero.types';

export function PaymentAccount() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryString = buildQueryString();

  const [selectedPaymentAccountOption, setSelectedPaymentAccountOption] =
    useState<DropdownOption>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<
    'getPaymentAccount' | 'putPaymentAccount'
  >();

  const sellerXeroContext = useXero();
  const companyName =
    contextLoadedSuccessfully(sellerXeroContext) &&
    sellerXeroContext.organisationName;

  const { stateKey } = useStateKey();
  const {
    data: paymentAccounts,
    error: paymentAccountsError,
    refetch: refetchPaymentAccounts,
  } = useQuery({
    queryKey: ['fetchGetXeroPaymentAccounts'],
    queryFn: () => fetchGetXeroPaymentAccounts(stateKey!),
    enabled: !!stateKey,
  });

  const paymentAccountOptions: DropdownOption[] =
    paymentAccounts?.data?.accounts.map((paymentAccount) => ({
      label: paymentAccount.name,
      value: paymentAccount.account_id,
    })) ?? [];

  const hasRefreshedContext = useRef(false);

  useEffect(() => {
    if (location?.state?.updateContext && !hasRefreshedContext.current) {
      hasRefreshedContext.current = true;
      void sellerXeroContext.refresh();
    }
  }, [sellerXeroContext, location]);

  const handleSubmit = async () => {
    if (
      selectedPaymentAccountOption &&
      !sellerXeroContext.loading &&
      !sellerXeroContext.error
    ) {
      setIsSubmitting(true);

      const response = await saveIwocapaySellerAccount(
        selectedPaymentAccountOption.value,
        sellerXeroContext.stateKey!,
      );

      if (!response.ok) {
        setError('putPaymentAccount');
        setIsSubmitting(false);
        return;
      }
      navigate && navigate('/pay/xero/invoice-branding-theme/' + queryString);
    }
  };

  return (
    <div className={sellerXeroStyles.sellerXero}>
      <div
        className={classnames(
          sellerXeroStyles.contentContainer,
          styles.paymentAccountContainer,
        )}
      >
        <div className={sellerXeroStyles.innerContainer}>
          <h4 className={sellerXeroStyles.companyTypeHeader}>{companyName}</h4>
          <div className={sellerXeroStyles.contentSm}>
            <ProgressBar totalSteps={3} currentStep={2} />
            <div className={styles.contentInner}>
              {error === undefined && (
                <DefaultContent
                  paymentAccountOptions={paymentAccountOptions}
                  setSelectedPaymentAccountOption={
                    setSelectedPaymentAccountOption
                  }
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  refetchPaymentAccounts={refetchPaymentAccounts}
                />
              )}
              {error === 'putPaymentAccount' && (
                <ErrorContent
                  paymentAccountOptions={paymentAccountOptions}
                  setSelectedPaymentAccountOption={
                    setSelectedPaymentAccountOption
                  }
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                />
              )}
              {!!paymentAccountsError && <TryAgainError />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function saveIwocapaySellerAccount(
  paymentAccountId: string,
  stateKey?: string,
) {
  return putLendingApiJson<PaymentAccountPut>({
    url: `/api/lending/edge/xero/iwocapay_seller/account/${stateKey}/`,
    body: {
      data: { payment_account_id: paymentAccountId },
    },
  });
}
