import { useSyncExternalStore } from 'react';

import { AlertBox } from '@iwoca/orion';
import cn from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import styles from './IwToast.module.css';
import TickIcon from '../assets/TickIcon.svg';

const TOAST_VARIANT = {
  SUCCESS: 'success',
  FAILURE: 'failure',
} as const;

type ValueOf<T extends Record<string, string>> = T[keyof T];
type TToastVariant = ValueOf<typeof TOAST_VARIANT>;

type TToastConfig = {
  timeout?: number;
  variant?: TToastVariant;
};
type TToast = {
  id: string;
  element: React.ReactNode;
  variant: TToastVariant;
};

type TListener = () => void;

class IwToastController {
  static toasts: TToast[] = [];
  static listeners: TListener[] = [];

  static subscribe(onStoreChange: TListener) {
    IwToastController.listeners.push(onStoreChange);

    return () => {
      IwToastController.listeners = IwToastController.listeners.filter(
        (listener) => listener !== onStoreChange,
      );
    };
  }

  static emitChange() {
    transition(() => {
      for (const listener of IwToastController.listeners) {
        listener();
      }
    });
  }

  static createToast(
    element: React.ReactNode,
    { timeout = 5000, variant = TOAST_VARIANT.SUCCESS }: TToastConfig = {},
  ) {
    const uuid = uuidv4();

    IwToastController.toasts = [
      ...IwToastController.toasts,
      {
        id: uuid,
        element,
        variant,
      },
    ];
    IwToastController.emitChange();

    setTimeout(() => {
      IwToastController.toasts = IwToastController.toasts.filter(
        ({ id }) => id !== uuid,
      );
      IwToastController.emitChange();
    }, timeout);
  }

  static getToasts() {
    return IwToastController.toasts;
  }

  static destroy() {
    IwToastController.toasts = [];
    IwToastController.listeners = [];
  }
}

/**
 * Supporting view transitions
 */
function transition(updateCb: () => void) {
  if (document.startViewTransition) {
    document.startViewTransition(updateCb);
  } else {
    updateCb();
  }
}

export function createToast(node: React.ReactNode, config?: TToastConfig) {
  return IwToastController.createToast(node, config);
}

export function resetToaster() {
  return IwToastController.destroy();
}

export function Toaster() {
  const toasts = useSyncExternalStore(
    IwToastController.subscribe,
    IwToastController.getToasts,
  );

  return (
    <div className={styles.toaster}>
      {toasts.map(({ element, id, variant }) => {
        return (
          <Toast key={id} id={id} variant={variant}>
            {element}
          </Toast>
        );
      })}
    </div>
  );
}

const Toast = ({
  className,
  id,
  variant = TOAST_VARIANT.SUCCESS,
  children,
  ...props
}: {
  className?: string;
  id?: string;
  variant?: TToastVariant;
  children: React.ReactNode;
}) => {
  const internalId = `toast${id ? `-${id}` : ''}`;

  return (
    <AlertBox
      data-testid={internalId}
      variant={variant}
      // @ts-expect-error I TELL YOU WHAT PROPS YOU ACCEPT!
      style={{ viewTransitionName: internalId }}
      className={cn(styles.toast, className, {
        [styles.success]: variant === TOAST_VARIANT.SUCCESS,
        [styles.failure]: variant === TOAST_VARIANT.FAILURE,
      })}
      {...props}
    >
      {variant === 'success' && (
        <img src={TickIcon} className={styles.tickIcon} alt="Tick icon" />
      )}
      {children}
    </AlertBox>
  );
};
