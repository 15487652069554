import React from 'react';

import styles from './CheckoutLayout.module.css';
import { CancelButton } from '../../../components/CancelButton/CancelButton';
import { HelpBox } from '../../../components/HelpBox/HelpBox';
import { useCurrentEcommerceOrder } from '../../hooks/useCurrentEcommerceOrder';
import { useCurrentPayLink } from '../../hooks/useCurrentPayLink';
import { RequirementsRouteConfig } from '../../routes';
import { CheckoutProgressIndicator } from '../CheckoutProgressIndicator';

export const CheckoutLayout = ({
  children,
  routes,
}: {
  children: React.ReactNode;
  routes: RequirementsRouteConfig[];
}) => {
  const { payLink } = useCurrentPayLink();
  const { ecommerceOrder } = useCurrentEcommerceOrder();
  const cancelUrl = payLink?.cancel_url || ecommerceOrder?.redirect_url;

  return (
    <>
      <div className={styles.Checkout}>
        <CheckoutProgressIndicator routes={routes} />
        <div className={styles.requirement}>
          <CancelButton
            className={styles.cancelButton}
            href={cancelUrl}
            orderId={ecommerceOrder?.id}
          />
          {children}
          <HelpBox />
        </div>
      </div>
    </>
  );
};
