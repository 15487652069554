import { useEffect, useRef } from 'react';

import { isEqual } from 'lodash-es';

function useDeepCompareMemoize(value: unknown) {
  const ref = useRef<unknown>();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffect(
  callback: React.EffectCallback,
  dependencies?: React.DependencyList,
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(callback, dependencies?.map(useDeepCompareMemoize));
}
