import React from 'react';

import cn from 'classnames';

import { SignupProgressIndicator } from './components/SignupProgressIndicator';
import styles from './SignupLayout.module.css';
import { useIsErrorStep } from './steps/useRedirectToLatestStep';
import { useCurrentEcommerceOrder } from '../Checkout/hooks/useCurrentEcommerceOrder';
import { useCurrentPayLink } from '../Checkout/hooks/useCurrentPayLink';
import { CancelButton } from '../components/CancelButton/CancelButton';
import { HelpBox } from '../components/HelpBox/HelpBox';

export const SignupLayout = ({ children }: { children: React.ReactNode }) => {
  const { payLink } = useCurrentPayLink();
  const { ecommerceOrder } = useCurrentEcommerceOrder();
  const cancelUrl = payLink?.cancel_url || ecommerceOrder?.redirect_url;

  return (
    <div className={cn(styles.Signup, 'text-left')}>
      <SignupProgressIndicator />
      <div className={styles.steps}>
        <CancelButton
          className={styles.cancelButton}
          orderId={ecommerceOrder?.id}
          href={cancelUrl}
        />
        {children}
        {!useIsErrorStep() && <HelpBox />}
      </div>
    </div>
  );
};
