import { useLocation } from 'react-router-dom';

import {
  ProgressIndicator,
  SECTION_TO_TITLE_MAPPING,
} from '../../../components/ProgressIndicator/ProgressIndicator';
import { sanitisePathname } from '../hooks/useNavigateToNextRequirement';
import {
  CHECKOUT_BASE_PATH,
  RequirementsRouteConfig,
  SECURITY_CHECKS_BASE_PATH,
} from '../routes';

export const CheckoutProgressIndicator = ({
  routes,
}: {
  routes: RequirementsRouteConfig[];
}) => {
  const progress = useCheckoutProgress({ routes });

  return <ProgressIndicator progress={progress} />;
};

function useCheckoutProgress({
  routes,
}: {
  routes: RequirementsRouteConfig[];
}) {
  const location = useLocation();

  const pathName = sanitisePathname(location.pathname);

  const { progress, isActive } = getCheckoutProgress({
    pathName: pathName,
    allowedRoutes: routes,
  });

  const [finalPage] = routes.slice(-1);
  const basePath =
    finalPage.name === 'Offer' ? CHECKOUT_BASE_PATH : SECURITY_CHECKS_BASE_PATH;
  const isFinalPage = pathName === basePath + finalPage.path + '/';

  return [
    {
      title: SECTION_TO_TITLE_MAPPING.details,
      percentage: 100,
      isActive: false,
    },
    {
      title: SECTION_TO_TITLE_MAPPING.fraud,
      percentage: progress,
      isActive: isActive,
    },
    {
      title:
        finalPage.name === 'Offer'
          ? SECTION_TO_TITLE_MAPPING.offer
          : SECTION_TO_TITLE_MAPPING.completed,
      percentage: isFinalPage ? 100 : 0,
      isActive: isFinalPage,
    },
  ];
}

const EXCLUDED_ROUTES = ['/offer', '/give-us-a-call', '/completed'];
function getCheckoutProgress({
  pathName,
  allowedRoutes,
}: {
  pathName: ReturnType<typeof useLocation>['pathname'];
  allowedRoutes: RequirementsRouteConfig[];
}) {
  const [expectedPath] = allowedRoutes.slice(-1);

  const basePath =
    expectedPath.name === 'Offer'
      ? CHECKOUT_BASE_PATH
      : SECURITY_CHECKS_BASE_PATH;

  if (pathName === basePath + expectedPath.path + '/') {
    return {
      progress: 100,
      isActive: false,
    };
  }

  const routes = allowedRoutes.filter((route) => {
    return !EXCLUDED_ROUTES.includes(route.path);
  });

  const routeIndex =
    routes.findIndex((route) => pathName === `${basePath}${route.path}/`) + 1;

  const checkoutProgress = (routeIndex / routes.length) * 100;

  return {
    progress: checkoutProgress,
    isActive: true,
  };
}
