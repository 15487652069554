import { Fragment, useEffect, useState } from 'react';

import { Combobox } from '@headlessui/react';
import {
  GetStateResponse,
  fetchPutState,
  fetchGetPostcodeLookup,
  GetPostcodeLookupResponse,
} from '@iwoca/lapi-client/edge';
import { Button, Input as OrionInput } from '@iwoca/orion';
import { useMutation } from '@tanstack/react-query';
import cn from 'classnames';
import { Form, Formik, useField } from 'formik';
import { cloneDeep, merge, set } from 'lodash-es';

import styles from './MajorShareholders.module.css';
import {
  denormalisePerson,
  normalizePerson,
  normalizeAddress,
} from './normalisation';
import PlusIcon from './svg/PlusIcon.svg';
import { shareholderValidator } from './validation';
import {
  useGetFundingRequirement,
  useGetStateByStateKey,
} from '../../../../api/lending/lapiHooks';
import { Accordion } from '../../../../Buyer/components/Accordion/Accordion';
import { Dropdown } from '../../../../components/Dropdown/Dropdown';
import { formatDate } from '../../../../components/Input/formatDate';
import { Input } from '../../../../components/Input/Input';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useStateKey } from '../../../../hooks/useStateKey.hook';
import { SubmitButton } from '../../../components/Button/Button';
import { Card } from '../../../components/Card/Card';
import { InlineButton } from '../../../components/InlineButton/InlineButton';
import { Divider } from '../../components/Divider/Divider';
import { useNavigateToNextRequirement } from '../../hooks/useNavigateToNextRequirement';

export const MajorShareholders = () => {
  const { goToNextRequirement, nextRequirementName } =
    useNavigateToNextRequirement();
  const { fetchFundingRequirements } = useGetFundingRequirement();

  const { state, loadingState, refetchState } = useGetStateByStateKey();
  const { stateKey } = useStateKey();

  const [currentlyEditingIndex, setEditingIndex] = useState<number | null>(
    null,
  );
  const [showAdding, setShowAdding] = useState(false);

  // TODO: figure out how to replace this with the `isLoading` flag from react-query
  const [submittingShareholder, setSubmittingShareholder] = useState(false);

  const onClickOption = (editingIndex?: number) => {
    if (!editingIndex) {
      setEditingIndex(null);
      setShowAdding(true);
      return;
    }

    setEditingIndex(editingIndex);
    setShowAdding(false);
  };

  const { isPending, mutateAsync } = useMutation({
    mutationFn: async (state: GetStateResponse) => {
      if (!stateKey) {
        throw new Error('No statekey provided');
      }

      await fetchPutState({ stateKey, body: state });

      await refetchState();
    },
  });

  const removeDirector = async (uid: string) => {
    if (!state) {
      throw new Error('No previous customer state found');
    }

    const newState = cloneDeep(state);

    const people = newState?.application?.people?.filter(
      (person) => person.uid !== uid,
    );

    set(newState, 'application.people', people);
    await mutateAsync({ data: newState });
    await fetchFundingRequirements();
  };

  const onSubmit = async (values: TPersonForm) => {
    if (!state) {
      throw new Error('No previous customer state found');
    }

    setSubmittingShareholder(true);

    const newState = cloneDeep(state);
    const newApplicant =
      newState.application?.people?.find(
        (person) => person.uid === values.uid,
      ) || {};

    const applicantState = denormalisePerson(values);
    merge(newApplicant, applicantState);

    const existingPersonIndex = state.application!.people!.findIndex(
      (person) => person.uid === values.uid,
    );

    if (existingPersonIndex >= 0) {
      set(newState, `application.people[${existingPersonIndex}]`, newApplicant);
    } else {
      const existingPeople = state!.application!.people || [];
      set(newState, 'application.people', [...existingPeople, newApplicant]);
    }

    await mutateAsync({ data: newState });
    await fetchFundingRequirements();

    setShowAdding(false);
    setSubmittingShareholder(false);
  };

  const people = state?.application?.people || [];
  const addedShareholders = people.filter(
    (person) =>
      person.roles?.includes('shareholder') &&
      !person.roles.includes('applicant'),
  );

  const isSubmitButtonDisabled =
    isPending || !addedShareholders.length || submittingShareholder;

  if (loadingState || !state) return <>Loading...</>;

  return (
    <Card title="Major shareholders info">
      <p className={styles.subtitle}>
        All the directors and shareholders that own 25% or more of your
        business.
      </p>
      <Accordion title="We need this to protect against fraud">
        <br></br>
        We’ll use this info for fraud and anti money laundering checks on all
        major shareholders.
      </Accordion>
      <div className={styles.shareholdersDialog}>
        {state.application?.people?.map((person, index) => (
          <DirectorCard
            key={person.uid}
            person={normalizePerson(person)}
            onSubmit={async (values) => {
              await onSubmit(values);
              setEditingIndex(null);
            }}
            removeDirector={removeDirector}
            onClickEdit={() => onClickOption(index)}
            showEditing={index === currentlyEditingIndex}
            loading={isPending}
          />
        ))}
      </div>
      <AddDirector
        onClickAdd={onClickOption}
        showAdding={showAdding}
        // Needs fixing. Types are not 100% identical
        // @ts-expect-error
        onSubmit={onSubmit}
        loading={isPending}
      />
      <Divider />
      <p>Are you finished adding all your business’ major shareholders?</p>

      <SubmitButton
        disabled={isSubmitButtonDisabled}
        onClick={goToNextRequirement}
      >
        {submittingShareholder ? (
          <LoadingSpinner />
        ) : (
          `Continue to ${nextRequirementName}`
        )}
      </SubmitButton>
    </Card>
  );
};

type TPersonForm = ReturnType<typeof normalizePerson>;
const DirectorCard = ({
  person,
  onSubmit,
  removeDirector,
  onClickEdit,
  showEditing = false,
  loading = false,
}: {
  person: TPersonForm;
  onSubmit: (values: TPersonForm) => void;
  removeDirector: (uid: string) => Promise<void>;
  onClickEdit: () => void;
  showEditing: boolean;
  loading: boolean;
}) => {
  const isPersonApplicant = person.roles.includes('applicant');

  if (showEditing) {
    return (
      <div className={styles.addShareholder}>
        <h3>Edit major shareholder</h3>
        <Formik
          initialValues={person}
          onSubmit={onSubmit}
          validationSchema={shareholderValidator}
        >
          <Form>
            <Input type="hidden" name="uid" />
            <Dropdown name="title" labelText="Title">
              <option value=""></option>
              <option value="mr">Mr</option>
              <option value="ms">Ms</option>
              <option value="mrs">Mrs</option>
              <option value="miss">Miss</option>
              <option value="sir">Sir</option>
              <option value="professor">Professor</option>
              <option value="doctor">Doctor</option>
              <option value="lord">Lord</option>
              <option value="lady">Lady</option>
              <option value="baron">Baron</option>
              <option value="baroness">Baroness</option>
              <option value="reverend">Reverend</option>
            </Dropdown>
            <Input name="firstName" label="First name" />
            <Input name="lastName" label="Last name" />
            <Input
              name="dateOfBirth"
              label="Date of birth (DD/MM/YYYY)"
              placeholder="DD/MM/YYYY"
              formatter={formatDate}
            />
            <AddressInput />
            <Button type="submit" variant="primary">
              {loading ? <LoadingSpinner /> : 'Save'}
            </Button>
          </Form>
        </Formik>
      </div>
    );
  }

  return (
    <div className={styles.existingCard}>
      <div className={styles.infoGroup}>
        {isPersonApplicant ? (
          <div>
            <span className={styles.pill}>
              <b>This is you</b>
            </span>
          </div>
        ) : null}
        <div className={cn(styles.infoText, 'fs-mask')}>
          {person.firstName} {person.lastName}
        </div>
        <div className={cn(styles.infoText, 'fs-mask')}>
          {person.dateOfBirth}
        </div>
        <div className={cn(styles.infoText, 'fs-mask')}>
          {normalizeAddress(person.address)}
        </div>
      </div>
      {!isPersonApplicant && (
        <div className={styles.controlButtons}>
          <InlineButton onClick={onClickEdit}>Edit</InlineButton>
          <InlineButton
            className={styles.deleteButton}
            onClick={() => removeDirector(person.uid)}
          >
            Remove
          </InlineButton>
        </div>
      )}
    </div>
  );
};

const DIRECTOR_INTIAL_VALUES = {
  title: '',
  firstName: '',
  lastName: '',
  address: {
    manual: false,
    streetName: '',
    buildingNumber: '',
    postcode: '',
    town: '',
  },
  dateOfBirth: '',
};

const AddDirector = ({
  onSubmit,
  onClickAdd,
  showAdding = false,
  loading = false,
}: {
  onSubmit: (values: typeof DIRECTOR_INTIAL_VALUES) => void;
  onClickAdd: () => void;
  showAdding: boolean;
  loading: boolean;
}) => {
  if (!showAdding)
    return (
      <div>
        <button
          className={styles.addShareholderButton}
          onClick={() => onClickAdd()}
        >
          Add major shareholder
          <img className={styles.plusIcon} src={PlusIcon} alt="Plus icon" />
        </button>
      </div>
    );

  return (
    <div className={styles.addShareholder}>
      <h3>Add a major shareholder</h3>
      <Formik
        initialValues={DIRECTOR_INTIAL_VALUES}
        enableReinitialize={false}
        onSubmit={onSubmit}
        validationSchema={shareholderValidator}
      >
        {() => {
          return (
            <Form>
              <Dropdown name="title" labelText="Title">
                <option value=""></option>
                <option value="mr">Mr</option>
                <option value="ms">Ms</option>
                <option value="mrs">Mrs</option>
                <option value="miss">Miss</option>
                <option value="sir">Sir</option>
                <option value="professor">Professor</option>
                <option value="doctor">Doctor</option>
                <option value="lord">Lord</option>
                <option value="lady">Lady</option>
                <option value="baron">Baron</option>
                <option value="baroness">Baroness</option>
                <option value="reverend">Reverend</option>
              </Dropdown>
              <Input name="firstName" label="First name" />
              <Input name="lastName" label="Last name" />
              <Input
                name="dateOfBirth"
                label="Date of birth (DD/MM/YYYY)"
                placeholder="DD/MM/YYYY"
                formatter={formatDate}
              />
              <AddressInput />
              <SubmitButton>
                {loading ? <LoadingSpinner /> : 'Confirm and add'}
              </SubmitButton>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const AddressInput = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [addressOptions, setAddressOptions] = useState<
    GetPostcodeLookupResponse['data'][number][]
  >([]);
  const [addressField, , { setValue: setAddressField }] = useField('address');
  const [isManualAddressField, , { setValue: setIsManualAddress }] =
    useField('address.manual');
  const [, { touched, error }] = useField('address.postcode');

  useEffect(() => {
    const postcodeSearch = setTimeout(async () => {
      if (searchQuery) {
        try {
          const response = await fetchGetPostcodeLookup({
            query: { postcode: searchQuery },
          });
          setAddressOptions(response.data);
        } catch {
          setAddressOptions([]);
        }
      } else {
        setAddressOptions([]);
      }
    }, 200);

    return () => {
      clearTimeout(postcodeSearch);
    };
  }, [searchQuery]);

  return (
    <div className={styles.addressInputContainer}>
      <Combobox
        value={addressField.value}
        onChange={async (
          address: GetPostcodeLookupResponse['data'][number],
        ) => {
          await setAddressField({
            buildingNumber: address.house_number,
            streetName: address.street_line_1,
            town: address.town,
            postcode: address.postcode,
            buildingName: address.house_name,
            equifaxToken: address.ptc_abs_code,
          });
          await setIsManualAddress(false);
        }}
      >
        <div className={styles.postcodeLookup}>
          <div className={styles.lookupInput}>
            <Combobox.Input
              onChange={(event) => {
                setSearchQuery(event.target.value);
              }}
              as={Fragment}
            >
              <OrionInput
                name="address.postcode"
                placeholder="Search postcode..."
                className={cn(styles.searchInput, 'fs-mask')}
                label="Home postcode"
                iconLeft="zoomOutline"
              />
            </Combobox.Input>
          </div>
          {!isManualAddressField.value && touched && error && (
            <span className={styles.error}>{error}</span>
          )}
          {searchQuery && (
            <Combobox.Options className={styles.options}>
              {addressOptions.length > 0 ? (
                addressOptions.map((address, index) => {
                  const addressName = [
                    address.house_name,
                    address.house_number,
                    address.street_line_1,
                  ]
                    .filter(Boolean)
                    .join(', ');
                  return (
                    <Combobox.Option
                      className={({ active }) =>
                        cn(styles.option, 'fs-mask', {
                          [styles.active]: active,
                        })
                      }
                      key={index}
                      value={address}
                    >
                      {addressName}
                    </Combobox.Option>
                  );
                })
              ) : (
                <p className={styles.noResults}>No results</p>
              )}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
      {!isManualAddressField.value &&
        (addressField.value.postcode ? (
          <>
            <div className={cn(styles.selectedAddressDetails, 'fs-mask')}>
              <div>
                {addressField.value.buildingName}{' '}
                {addressField.value.buildingNumber}{' '}
                {addressField.value.streetName}
              </div>
              <div>{addressField.value.town}</div>
              <div>{addressField.value.postcode}</div>
            </div>
            <InlineButton onClick={() => setIsManualAddress(true)}>
              Edit address
            </InlineButton>
          </>
        ) : (
          <InlineButton
            onClick={async () => {
              await setAddressField({
                ...addressField.value,
                equifaxToken: '',
              });
              await setIsManualAddress(!isManualAddressField.value);
            }}
          >
            Enter address manually
          </InlineButton>
        ))}
      {isManualAddressField.value && (
        <div>
          <Input label="Building number" name="address.buildingNumber" />
          <Input label="Street name" name="address.streetName" />
          <Input label="Town" name="address.town" />
          <Input label="Postcode" name="address.postcode" />
        </div>
      )}
    </div>
  );
};
