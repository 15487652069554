import referralIcon from './assets/referral.svg';
import rocketIcon from './assets/rocket.svg';
import { ANTCard } from './components/ANTCard/ANTCard';
import { PaymentActivity } from './components/PaymentActivity/PaymentActivity';
import { SellerDashboardCard } from './components/SellerDashboardCard/SellerDashboardCard';
import { SellerDashboardStatusSummary } from './components/SellerDashboardStatusSummary';
import { SpendingLimitBanner } from './components/SpendingLimitBanner/SpendingLimitBanner';
import styles from './Dashboard.module.css';
import { useGetSellerConnection } from '../../api/lending/lapiHooks';
import { CreatePayLinkButton } from '../../components/SellerNavBar/SellerNavBar';
import { useUserGroups } from '../../hooks/useUserGroups';
import { SellerInnerPageTemplate } from '../../routing/templates';
import { CrossSellCard } from '../CrossSellCard/CrossSellCard';
import magentoLogo from '../Integrations/assets/magentoLogo.svg';
import wooCommerceLogo from '../Integrations/assets/wooLogo.svg';
import xeroLogo from '../Integrations/assets/xeroLogo.svg';
import { IntegrationName } from '../Integrations/utils/integrations.types';

export const Dashboard = () => {
  const showAccountancyCard = useIsIntegrationCardVisible(['xero']);
  const showEcommerceCard = useIsIntegrationCardVisible([
    'woocommerce',
    'magento',
  ]);
  const { isOwner, isStaff } = useUserGroups();

  return (
    <SellerInnerPageTemplate>
      <div className={styles.container}>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            {isOwner || isStaff ? <ANTCard /> : null}
            <SpendingLimitBanner />
            <SellerDashboardStatusSummary />
            <PaymentActivity />
          </div>
          <div className={styles.cards}>
            {isOwner || isStaff ? <CrossSellCard /> : null}
            {showAccountancyCard && (
              <SellerDashboardCard
                icons={[{ icon: xeroLogo, alt: 'Xero Logo' }]}
                title="Connect iwocaPay to your accounting software"
                link={{
                  text: 'See our accountancy plugins',
                  href: '/pay/integrations',
                }}
              >
                Automatically reconcile payments and give your customers the
                option to pay their invoices with iwocaPay.
              </SellerDashboardCard>
            )}
            {showEcommerceCard && (
              <SellerDashboardCard
                icons={[
                  { icon: wooCommerceLogo, alt: 'WooCommerce Logo' },
                  { icon: magentoLogo, alt: 'Magento Logo' },
                ]}
                title="Add iwocaPay to your checkout"
                link={{
                  text: 'See our ecommerce plugins',
                  href: '/pay/integrations',
                }}
              >
                Easily upgrade your ecommerce checkout and offer a Buy Now Pay
                Later payment method.
              </SellerDashboardCard>
            )}
            <SellerDashboardCard
              icons={[{ icon: rocketIcon, alt: 'Rocket Icon' }]}
              title="Get the iwocaPay launch pack"
              link={{
                text: 'Get the launch pack',
                href: 'https://www.coda.io/d/Welcome-to-iwocaPay_dx9e47R5IPf',
              }}
              externalLink={true}
            >
              Assets, templates, tips and tricks for using iwocaPay to grow your
              business.
            </SellerDashboardCard>
            <SellerDashboardCard
              icons={[{ icon: referralIcon, alt: 'Referral Icon' }]}
              title="Get rewarded for recommending iwocaPay"
              link={{
                text: 'Refer a supplier to iwocaPay',
                href: '/pay/rewards',
              }}
            >
              Recommend iwocaPay to a supplier and you’ll both get a £100
              voucher of your choice once they’re set up.
            </SellerDashboardCard>
          </div>
        </div>
        <div className={styles.footer}>
          <CreatePayLinkButton />
        </div>
      </div>
    </SellerInnerPageTemplate>
  );
};

const useIsIntegrationCardVisible = (integrations: IntegrationName[]) => {
  const { iwocapaySellerConnection, loadingIwocapaySellerConnection } =
    useGetSellerConnection();
  if (loadingIwocapaySellerConnection || !iwocapaySellerConnection) return null;
  for (const integration of integrations) {
    if (iwocapaySellerConnection[integration] === 'CONNECTED') {
      return false;
    }
  }
  return true;
};
