import {
  GetSellerSpendingLimitRequestResponse,
  LapiError,
} from '@iwoca/lapi-client/iwocapay';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { useStateKey } from '../../../hooks/useStateKey.hook';
import { lendingApiFetchJson } from '../../../Pages/lendingApiFetch';

type TSpendingLimitQueryParams = {
  page?: number;
};

async function fetchGetSellerSpendingLimitRequest({
  stateKey,
  query,
}: {
  stateKey: string;
  query: string;
}): Promise<GetSellerSpendingLimitRequestResponse> {
  const res = await lendingApiFetchJson(
    `/api/iwocapay/seller/${stateKey}/spending_limit_request/${query}`,
  );

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  return res.json();
}

export const useGetSellerSpendingLimitRequest = ({
  page,
}: TSpendingLimitQueryParams = {}) => {
  const { stateKey } = useStateKey();

  const query = buildSellerSpendingLimitParams({
    page: page || 1,
  });

  const { data, isLoading } = useQuery({
    queryKey: ['fetchGetSellerSpendingLimitRequest', query],
    queryFn: () =>
      fetchGetSellerSpendingLimitRequest({
        stateKey: stateKey!,
        query: query,
      }),
    placeholderData: keepPreviousData,
    enabled: Boolean(stateKey),
  });

  return {
    spendingLimitRequests: data?.data || [],
    metadata: data?.meta,
    isLoading: isLoading,
    paginationLinks: data?.links,
    totalCount: data?.meta?.count,
  };
};

const buildSellerSpendingLimitParams = ({
  page,
}: TSpendingLimitQueryParams) => {
  const params = new URLSearchParams();
  if (page && page !== 1) {
    params.append('page', `${page}`);
  }
  if (params.getAll.length === 0) return '';

  return `?${params.toString()}`;
};
