import { useState } from 'react';

import { GetXeroConnectionsResponse } from '@iwoca/lapi-client/edge';
import Select from 'react-select';

import styles from './SelectConnection.module.css';
import { Button } from '../../../../components/Button/Button';
import { LaptopIcon } from '../../../../components/svg/LaptopIcon';
import UnhappyLaptopIcon from '../../../../components/svg/UnhappyLaptopIcon.svg?react';
import { iwocapayTelDisplay } from '../../../../constants.json';
import { useXero } from '../../Xero.context';
import { contextLoadedSuccessfully } from '../../Xero.helpers';
import { DropdownOption } from '../../Xero.types';

export const SelectConnection = ({
  postSellerAccountAndRedirect,
}: {
  postSellerAccountAndRedirect: (
    stateKey: string,
    tenantId: string,
  ) => Promise<void>;
}) => {
  const [error, setError] = useState(false);

  const sellerXeroContext = useXero();
  const connections = contextLoadedSuccessfully(sellerXeroContext)
    ? formatConnections(sellerXeroContext.xeroConnections!)
    : [];
  const [selectedConnection, setSelectedConnection] = useState<DropdownOption>(
    connections[0],
  );

  const onConfirm = async () => {
    if (contextLoadedSuccessfully(sellerXeroContext)) {
      await postSellerAccountAndRedirect(
        sellerXeroContext.stateKey!,
        selectedConnection.value,
      );

      setError(true);
    }
  };

  return (
    <div className={styles.contentWrapper}>
      {!error ? (
        <>
          <div className={styles.textContent}>
            <h1>Link your Xero account</h1>
            <p>
              You can only link one organisation in your Xero account to
              iwocaPay at any point. Please select which organisation you'd like
              to link
            </p>
          </div>
          <LaptopIcon className={styles.laptopIcon} />
          <Select
            className={styles.dropdown}
            placeholder="Loading..."
            options={connections}
            value={selectedConnection}
            onChange={(selectedConnection) =>
              setSelectedConnection(selectedConnection as DropdownOption)
            }
          />
          <Button onClick={onConfirm} className={styles.button}>
            Confirm
          </Button>
        </>
      ) : (
        <>
          <div className={styles.textContent}>
            <h1>Hmm, something didn't work that time!</h1>
            <p>Try again, or call us on {iwocapayTelDisplay}</p>
          </div>
          <UnhappyLaptopIcon />
        </>
      )}
    </div>
  );
};

function formatConnections(
  connections: GetXeroConnectionsResponse['data']['connections'],
): DropdownOption[] {
  return connections.map((connection) => ({
    label: connection.tenant_name!,
    value: connection.tenant_id!,
  }));
}
