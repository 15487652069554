import { useState } from 'react';

import { useDeepCompareEffect } from '../../../hooks/useDeepCompareEffect';
import { useStateKey } from '../../../hooks/useStateKey.hook';
import { useXero } from '../Xero.context';
import { contextLoadedSuccessfully, saveXeroThemes } from '../Xero.helpers';
import { DropdownOption } from '../Xero.types';

export function useSelectXeroThemes() {
  const { themeOptions, ...sellerXeroContext } = useXero();
  const { stateKey } = useStateKey();

  const [selectedOptions, setSelectedOptions] = useState<DropdownOption[]>([]);

  useDeepCompareEffect(() => {
    setSelectedOptions(themeOptions.filter((theme) => theme.isFixed));
  }, [themeOptions]);

  const saveSelectedThemes = async () => {
    if (!selectedOptions || !contextLoadedSuccessfully(sellerXeroContext)) {
      return;
    }

    if (!stateKey) {
      throw new Error('No Statekey provided to save Xero themes');
    }

    const apiThemeIds = selectedOptions.map((option) => ({
      branding_theme_id: option.value,
    }));

    await saveXeroThemes(apiThemeIds, stateKey);
    void sellerXeroContext.refresh();
  };

  const clearThemes = () => {
    const fixedOptions = selectedOptions.filter((option) => option.isFixed);
    setSelectedOptions(fixedOptions);
  };

  return {
    selectedThemeOptions: selectedOptions,
    setSelectedThemeOptions: setSelectedOptions,
    clearThemes,
    themeOptions,
    hasThemesSelectionChanged:
      selectedOptions && selectedOptions.some((theme) => !theme.isFixed),
    saveSelectedThemes,
  };
}
