import classnames from 'classnames';

import styles from './StatusTemplate.module.css';
import ChevronRight from '../../../components/svg/ChevronRight.svg';
import CTARocket from '../../../components/svg/CTABanner.svg';
import TelephoneIcon from '../../../components/svg/SolidTelephone.svg';
import { iwocapayTelDisplay, iwocapayTelLink } from '../../../constants.json';

export const StatusTemplate = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.confirmationBox}>
        <div className={styles.content}>{children}</div>
        <BecomeASellerCTA />
        <div className={styles.needHelpContainer}>
          <div className={styles.needHelpText}>Need some help?</div>
          <div>
            <div className={styles.openHours}>
              <img
                src={TelephoneIcon}
                className={styles.telephoneIcon}
                alt=""
              />
              <span className={styles.openText}>Open</span> Mon 9-6pm
            </div>
            <div className={styles.phoneNumber}>
              <a href={iwocapayTelLink}>{iwocapayTelDisplay}</a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.semiCircleContainer}>
        <div className={styles.semiCircle} />
        <div className={styles.semiCircle} />
        <div className={styles.semiCircle} />
        <div className={styles.semiCircle} />
        <div className={styles.semiCircle} />
        <div className={styles.semiCircle} />
        <div className={styles.semiCircle} />
        <div className={styles.semiCircle} />
        <div className={styles.semiCircle} />
        <div className={classnames(styles.semiCircle, styles.desktopOnly)} />
        <div className={classnames(styles.semiCircle, styles.desktopOnly)} />
        <div className={classnames(styles.semiCircle, styles.desktopOnly)} />
      </div>
    </div>
  );
};

const BecomeASellerCTA = () => {
  return (
    <div className={styles.sellerCTABanner}>
      <div className={styles.ctaTextPortion}>
        <div className={styles.headlinePortion} data-testid="cta-ad-text">
          Access £1,000 - £1,000,000
          <br />
          today with an iwoca
          <br />
          business loan
        </div>
        <div className={styles.applyContainer}>
          <a
            href="https://www.iwoca.co.uk/?utm_source=internal-website&utm_medium=iwocapay&utm_campaign=buyers#compare"
            className={styles.applyLink}
          >
            Apply in minutes here
          </a>
          <img src={ChevronRight} alt={'Apply here'} />
        </div>
      </div>
      <div className={styles.ctaRocket}>
        <img src={CTARocket} className={styles.rocket} alt={''} width="120%" />
      </div>
    </div>
  );
};
