import { postLendingApiJson } from '../../Pages/lendingApiFetch';

export function contextLoadedSuccessfully(context: {
  loading: boolean;
  error: Record<string, unknown> | null;
}) {
  return !context.loading;
}

export function saveXeroThemes(
  apiThemeIds: { branding_theme_id: string }[],
  stateKey: string,
) {
  return postLendingApiJson({
    url: `/api/lending/edge/xero/iwocapay_seller/branding_themes/${stateKey}/payment_services/`,
    body: {
      data: {
        branding_themes: apiThemeIds,
      },
    },
  });
}
