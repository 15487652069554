import { GetSellerPayLinkResponse } from '@iwoca/lapi-client/iwocapay';

import styles from '../PayLinkTable.module.css';

const DASHBOARD_PAY_LINK_STATUS = {
  NOT_STARTED: 'Not started',
  DECLINED: 'Declined',
  UNKNOWN: 'Unknown',
  CANCELLED: 'Cancelled',
  PENDING: 'Pending funds',
  PAID: "You've been paid 🙌",
  STARTED: 'Started',
  ELIGIBLE: 'Eligible',
  PARTIALLY_APPROVED: 'Partially approved',
} as const;

export type TDashboardPaylinkStatus =
  (typeof DASHBOARD_PAY_LINK_STATUS)[keyof typeof DASHBOARD_PAY_LINK_STATUS];

type TPayLinkStatusMap = Record<
  Exclude<GetSellerPayLinkResponse['data'][number]['status'], undefined>,
  TDashboardPaylinkStatus
>;

const STATUS_TO_FRIENDLY_STATUS = {
  NOT_SIGNED_UP: DASHBOARD_PAY_LINK_STATUS.NOT_STARTED,
  SIGNED_UP: DASHBOARD_PAY_LINK_STATUS.STARTED,
  APPROVED: DASHBOARD_PAY_LINK_STATUS.ELIGIBLE,
  PARTIALLY_APPROVED: DASHBOARD_PAY_LINK_STATUS.PARTIALLY_APPROVED,
  SCHEDULED_FOR_CAPTURE: DASHBOARD_PAY_LINK_STATUS.PENDING,
  PAID: DASHBOARD_PAY_LINK_STATUS.PAID,
  DECLINED: DASHBOARD_PAY_LINK_STATUS.DECLINED,
  CANCELLED: DASHBOARD_PAY_LINK_STATUS.CANCELLED,
} as const satisfies TPayLinkStatusMap;

export const mapPayLinkStatusToFriendly = (
  status: GetSellerPayLinkResponse['data'][number]['status'],
): TDashboardPaylinkStatus => {
  if (!status) {
    return DASHBOARD_PAY_LINK_STATUS.UNKNOWN;
  }

  return STATUS_TO_FRIENDLY_STATUS[status] || DASHBOARD_PAY_LINK_STATUS.UNKNOWN;
};

export const STATUS_TO_COLOUR = {
  [DASHBOARD_PAY_LINK_STATUS.NOT_STARTED]: [
    styles.clearPip,
    styles.clearPip,
    styles.clearPip,
  ],
  [DASHBOARD_PAY_LINK_STATUS.STARTED]: [
    styles.blackPip,
    styles.clearPip,
    styles.clearPip,
  ],
  [DASHBOARD_PAY_LINK_STATUS.ELIGIBLE]: [
    styles.greenPip,
    styles.blackPip,
    styles.clearPip,
  ],
  [DASHBOARD_PAY_LINK_STATUS.PENDING]: [
    styles.greenPip,
    styles.greenPip,
    styles.yellowPip,
  ],
  [DASHBOARD_PAY_LINK_STATUS.PAID]: [
    styles.greenPip,
    styles.greenPip,
    styles.greenPip,
  ],
  [DASHBOARD_PAY_LINK_STATUS.DECLINED]: [],
  [DASHBOARD_PAY_LINK_STATUS.CANCELLED]: [],
  [DASHBOARD_PAY_LINK_STATUS.UNKNOWN]: [],
  [DASHBOARD_PAY_LINK_STATUS.PARTIALLY_APPROVED]: [
    styles.greenPip,
    styles.greenDashedPip,
    styles.clearPip,
  ],
} as const satisfies Record<TDashboardPaylinkStatus, readonly string[]>;

export const STATUS_TO_TOOLTIP = {
  [DASHBOARD_PAY_LINK_STATUS.NOT_STARTED]:
    'Your customer’s not yet started their application',
  [DASHBOARD_PAY_LINK_STATUS.STARTED]:
    'Your customer has started their application, but not finished it yet',
  [DASHBOARD_PAY_LINK_STATUS.ELIGIBLE]:
    'Your customer is eligible but still needs to finish a few things',
  [DASHBOARD_PAY_LINK_STATUS.PENDING]:
    'You have received an order which needs to be confirmed for us to release the funds to you',
  [DASHBOARD_PAY_LINK_STATUS.DECLINED]:
    'They are not eligible for iwocaPay at this time',
  [DASHBOARD_PAY_LINK_STATUS.CANCELLED]: 'You cancelled this paylink',
  [DASHBOARD_PAY_LINK_STATUS.UNKNOWN]: null,
  [DASHBOARD_PAY_LINK_STATUS.PARTIALLY_APPROVED]:
    'The customer’s order is higher than their approved spending limit',
} as const satisfies Record<
  Exclude<TDashboardPaylinkStatus, typeof DASHBOARD_PAY_LINK_STATUS.PAID>,
  string | null
>;
