import { Button } from '@iwoca/orion';
import { useFormikContext } from 'formik';

import styles from './BusinessInformation.module.css';
import { formatCurrency } from '../../../../components/Input/formatCurrency';
import { formatDate } from '../../../../components/Input/formatDate';
import { Input } from '../../../../components/Input/Input';
import { CrossSellFormValues } from '../../utils/CrossSellCard.types';

export const BusinessInformation = () => {
  const { isValid } = useFormikContext<CrossSellFormValues>();

  return (
    <div>
      <Input
        name="turnover"
        label="Last 12 months turnover"
        labelDescriptionText="Doesn't need to be exact, but as close as you can."
        placeholder="Enter amount..."
        className={styles.input}
        formatter={formatCurrency}
      />
      <Input
        name="amountRequested"
        label="How much would you like to borrow?"
        labelDescriptionText="Between £1,000 and £1,000,000"
        placeholder="e.g. 150,000"
        className={styles.input}
        formatter={formatCurrency}
      />
      <Input
        name="startedTrading"
        label="When did your business start trading?"
        placeholder="dd/mm/yyyy"
        className={styles.input}
        formatter={formatDate}
      />
      <Button
        disabled={!isValid}
        className={styles.button}
        type="submit"
        variant="secondary"
      >
        Continue
      </Button>
    </div>
  );
};
