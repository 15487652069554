import { Button } from '@iwoca/orion';
import classnames from 'classnames';

import styles from './CancelButton.module.css';
import { deleteLendingApi } from '../../../Pages/lendingApiFetch';

export const CancelButton = ({
  href,
  orderId,
  ...props
}: {
  href?: string;
  orderId?: string;
} & React.HTMLAttributes<HTMLButtonElement>) => {
  if (!href) return null;

  const handleCancel = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (orderId) {
      await deleteLendingApi(`/api/lending/edge/ecommerce/order/${orderId}/`);
    }
    window.location.href = href;
  };

  return (
    <Button
      {...props}
      className={classnames(styles.cancelButton, props.className)}
      variant="tertiary"
      onClick={handleCancel}
    >
      Cancel order
    </Button>
  );
};
