import React from 'react';

import { WebhooksTable } from './WebhooksTable';
import { SellerInnerPageTemplate } from '../../../../routing/templates';

export const WebhooksPage = () => {
  return (
    <SellerInnerPageTemplate title="Webhook Logs">
      <WebhooksTable />
    </SellerInnerPageTemplate>
  );
};
