import React from 'react';

import { CheckoutLayout } from './components/CheckoutLayout/CheckoutLayout';
import { DrawDownModal } from './components/DrawDownModal/DrawDownModal';
import { useCurrentEcommerceOrder } from './hooks/useCurrentEcommerceOrder';
import { useCurrentPayLink } from './hooks/useCurrentPayLink';
import { DEFAULT_ROUTE_CONFIG } from './routes';
import {
  useGetApplication,
  useGetFormalOffers,
  useGetFundingRequirement,
  useGetLoanDocumentsRequiredIwocapay,
  useGetStateByStateKey,
} from '../../api/lending/lapiHooks';
import { useQueryParam } from '../../hooks/useQueryParam';
import { LoadingCard } from '../components/LoadingCard/LoadingCard';
import { CapturedPaymentDrawdownProvider } from '../Signup/CapturedPaymentDrawdownProvider';
import { CapturedPaymentDrawdownModal } from '../Signup/components/CapturedPaymentDecisionModal/CapturedPaymentDrawdownModal';
import { ModalProvider } from '../store/ModalProvider';

export const Checkout = ({ children }: { children: React.ReactNode }) => {
  const { application, loadingApplication } = useGetApplication();
  const { state, loadingState } = useGetStateByStateKey();
  const { formalOffers, loadingFormalOffers } = useGetFormalOffers();
  const { payLink, loadingPayLink } = useCurrentPayLink();
  const { fundingRequirement, loadingFundingRequirement } =
    useGetFundingRequirement();
  const { ecommerceOrder, loadingEcommerceOrder } = useCurrentEcommerceOrder();
  const {
    loanDocumentsRequiredIwocapay,
    loadingLoanDocumentsRequiredIwocapay,
  } = useGetLoanDocumentsRequiredIwocapay();

  // TODO: Check payLinkId is present
  // TODO: Check iwocaPay latest formal offer
  // TODO: Check non-expired offer
  // TODO: Check paylink is not funded yet
  const orderId = useQueryParam('orderId');
  const hasLoadedEcommerce =
    (Boolean(ecommerceOrder) && !loadingEcommerceOrder) || !orderId;

  const hasLoaded =
    application &&
    !loadingApplication &&
    state &&
    !loadingState &&
    formalOffers &&
    !loadingFormalOffers &&
    fundingRequirement &&
    !loadingFundingRequirement &&
    payLink &&
    !loadingPayLink &&
    loanDocumentsRequiredIwocapay &&
    !loadingLoanDocumentsRequiredIwocapay &&
    hasLoadedEcommerce;

  return (
    <ModalProvider>
      <CheckoutLayout routes={DEFAULT_ROUTE_CONFIG}>
        <CapturedPaymentDrawdownProvider>
          <div className="text-left">
            {hasLoaded ? (
              <>
                {children}
                <DrawDownModal />
              </>
            ) : (
              <LoadingCard />
            )}
          </div>
          <CapturedPaymentDrawdownModal />
        </CapturedPaymentDrawdownProvider>
      </CheckoutLayout>
    </ModalProvider>
  );
};
