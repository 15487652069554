import { Button } from '@iwoca/orion';
import { useParams } from 'react-router-dom';

import styles from './OrderLandingPage.module.css';
import { useGetEcommerceOrder } from '../../../api/lending/lapiHooks';
import { LinkWithQuery } from '../../../components/LinkWithQuery/LinkWithQuery';
import { LoadingScreen } from '../../../components/LoadingScreen/LoadingScreen';
import Chevron from '../../../components/svg/ChevronLeft.svg';
import IwocaPayIcon from '../../../components/svg/IwocaPayIconSmall.svg';
import { OrderLinkIsNotValid } from '../../../Pages/FailurePages/OrderLinkIsNotValid';
import { OrderNotFound } from '../../../Pages/FailurePages/OrderNotFound';
import BenefitIwocaPay from '../assets/BenefitIwocaPay.svg';
import BenefitPencil from '../assets/BenefitPencil.svg';
import BenefitPieChart from '../assets/BenefitPieChart.svg';
import OrderLandingPageGraphic from '../assets/OrderLandingPageGraphic.jpg';

const OrderLandingPage = ({ showCheckout }: { showCheckout: () => void }) => {
  const { orderId } = useParams();

  const { ecommerceOrder, loadingEcommerceOrder } = useGetEcommerceOrder({
    orderId,
  });

  if (loadingEcommerceOrder) {
    return <LoadingScreen />;
  }

  if (!ecommerceOrder) {
    return <OrderNotFound />;
  }

  if (ecommerceOrder.status !== 'CREATED') {
    return <OrderLinkIsNotValid />;
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.centerContent}>
        <a className={styles.returnLink} href={ecommerceOrder.redirect_url}>
          <span className={styles.chevron}>
            <img src={Chevron} alt="" />
          </span>
          Return to {ecommerceOrder.seller_trading_name}
        </a>

        <div className={styles.modal}>
          <img
            className={styles.headingImage}
            src={OrderLandingPageGraphic}
            alt="Landing page graphic"
          />
          <div className={styles.content}>
            <div className={styles.header}>
              <h1 className={styles.heading}>Pay later for businesses</h1>
              <p className={styles.poweredBy}>
                Powered by
                <LinkWithQuery
                  to="/iwocapay-sellers/"
                  className={styles.iwocaPayLogoLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    className={styles.iwocaPayLogo}
                    src={IwocaPayIcon}
                    alt="iwocaPay logo"
                  />
                </LinkWithQuery>
              </p>
            </div>
            <ul className={styles.benefits}>
              <li className={styles.benefit}>
                <span className={styles.benefitIcon}>
                  <img src={BenefitPieChart} alt="" />
                </span>
                <div>
                  <h2 className={styles.benefitHeading}>
                    Spread the cost
                    {ecommerceOrder.pricing.representative_interest === 0 &&
                      ' interest-free'}
                  </h2>
                  <p className={styles.benefitText}>
                    Equal monthly payments taken automatically - the first one
                    is taken 30 days after you’ve checked out.
                  </p>
                </div>
              </li>
              <li className={styles.benefit}>
                <span className={styles.benefitIcon}>
                  <img src={BenefitIwocaPay} alt="" />
                </span>
                <div>
                  <h2 className={styles.benefitHeading}>
                    Over £3 billion across 50,000 small businesses
                  </h2>
                  <p className={styles.benefitText}>
                    We’re backed by iwoca, one of the largest independent
                    Business Funding companies in the UK.
                  </p>
                </div>
              </li>
              <li className={styles.benefit}>
                <span className={styles.benefitIcon}>
                  <img src={BenefitPencil} alt="" />
                </span>
                <div>
                  <h2 className={styles.benefitHeading}>
                    Never fill in a form again
                  </h2>
                  <p className={styles.benefitText}>
                    Do it once, and we’ll remember your details for next time
                    you use your iwocaPay account.
                  </p>
                </div>
              </li>
            </ul>
            <Button className="w-full" size="large" onClick={showCheckout}>
              Head to checkout
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderLandingPage;
