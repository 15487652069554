import styles from './DecisionModal.module.css';
import { DecisionModalIcon, DecisionModalIconType } from './DecisionModalIcon';
import { CrossIcon } from '../../../../components/svg/CrossIcon';
import {
  iwocapayTelDisplay,
  iwocapayTelLink,
} from '../../../../constants.json';
import { Button } from '../../../components/Button/Button';
import { useModal } from '../../../store/ModalProvider';
import { DecisionErrorTypes } from '../../ApprovalRequirements';
import { useDecision } from '../../DecisionProvider';
import { DIRECTOR_INFO_STEP_ID } from '../../steps/Steps';
import { useNavigateToStep } from '../../steps/useNavigateToStep';

const DECISION_MODAL_ID = 'decisionModal';

export const ErrorModal = () => {
  const { decisionError } = useDecision();
  const { closeModal } = useModal(DECISION_MODAL_ID);
  const goToDirectorInfoStep = useNavigateToStep(DIRECTOR_INFO_STEP_ID);

  if (decisionError === DecisionErrorTypes.DIRECTORS_COMPANY_MISMATCH_ERROR) {
    return (
      <>
        <CrossIcon className={styles.closeButton} onClick={closeModal} />
        <DecisionModalIcon type={DecisionModalIconType.ERROR} />
        <p className={styles.unknown}>Something doesn't look right</p>
        <div className={styles.description}>
          There is an error matching your details.
          <br />
          Please enter your full legal name
        </div>
        <Button
          className={styles.checkButton}
          onClick={() => {
            goToDirectorInfoStep();
            closeModal();
          }}
        >
          Edit details
        </Button>
      </>
    );
  }

  if (decisionError === DecisionErrorTypes.INCOMPLETE_CREDIT_CHECK_ERROR) {
    return (
      <>
        <CrossIcon className={styles.closeButton} onClick={closeModal} />
        <DecisionModalIcon type={DecisionModalIconType.ERROR} />
        <p className={styles.unknown}>Something doesn't look right</p>
        <div className={styles.description}>Please check you've:</div>
        <ul className={styles.list}>
          <li>used your full legal name - not a shortened version</li>
          <li>used your personal address - not your business address</li>
          <li>
            if you've moved in the last few months, enter your previous address
          </li>
        </ul>
        <Button
          className={styles.checkButton}
          onClick={() => {
            goToDirectorInfoStep();
            closeModal();
          }}
        >
          Edit details
        </Button>
        <div className={styles.needHelp}>
          Need some help? Give us a call on{' '}
          <a href={iwocapayTelLink} className={styles.needHelpNumber}>
            {iwocapayTelDisplay}
          </a>
        </div>
      </>
    );
  }

  return (
    <>
      <CrossIcon className={styles.closeButton} onClick={closeModal} />
      <DecisionModalIcon type={DecisionModalIconType.UNKNOWN} />

      <p className={styles.unknown}>Something went wrong...</p>
      <div className={styles.description}>
        <>
          <p>Please check your details.</p>
          <p>
            Need help? If so please give us a call{' '}
            <a href={iwocapayTelLink}>{iwocapayTelDisplay}</a>.
          </p>
          <Button
            className={styles.checkButton}
            onClick={() => {
              closeModal();
            }}
          >
            Edit details
          </Button>
        </>
      </div>
    </>
  );
};
