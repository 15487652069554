import { useState } from 'react';

import classNames from 'classnames';

import styles from './IntegrationsDashboard.module.css';
import ArrowSVG from '../../../../components/svg/ChevronRight.svg';
import MagnifierGlassSVG from '../../../../components/svg/MagnifierGlass.svg';
import RainbowSVG from '../../../../components/svg/Rainbow.svg';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { SellerInnerPageTemplate } from '../../../../routing/templates';
import { ExploreIntegrationsButton } from '../../components/ExploreIntegrationsButton/ExploreIntegrationsButton';
import { IntegrationButton } from '../../components/IntegrationButton/IntegrationButton';
import { integrationsList } from '../../Integrations';
import { IntegrationsHeader } from '../IntegrationsHeader/IntegrationsHeader';

export function IntegrationsDashboard() {
  const [integrationListOpen, setIntegrationListOpen] =
    useState<boolean>(false);

  const integrationButtonHandleClick = () => {
    setIntegrationListOpen(false);
  };

  const integrationListHandleClick = () => {
    setIntegrationListOpen(!integrationListOpen);
  };

  const { isMobile } = useIsMobile();

  return (
    <SellerInnerPageTemplate title="Integrations">
      <div className={styles.integrationsDashboard}>
        <IntegrationsHeader />
        {!isMobile && (
          <div className={styles.container}>
            <div className={styles.integrationList}>
              {integrationsList.map((integration) => (
                <IntegrationButton
                  integration={integration}
                  handleClick={integrationButtonHandleClick}
                  key={`${integration.company_name}__button-desktop`}
                />
              ))}
              <IntegrationBanners />
            </div>
            <div className={styles.body}>
              {integrationsList.map((integration) => (
                <integration.body
                  key={`${integration.company_name}__body-desktop`}
                />
              ))}
            </div>
          </div>
        )}
        {isMobile && (
          <div className={styles.container}>
            <div className={styles.integrationsButtonContainer}>
              <ExploreIntegrationsButton
                integrationListOpen={integrationListOpen}
                handleClick={integrationListHandleClick}
              />
            </div>
            {integrationListOpen && (
              <div className={styles.integrationList}>
                {integrationsList.map((integration) => (
                  <IntegrationButton
                    integration={integration}
                    handleClick={integrationButtonHandleClick}
                    key={`${integration.company_name}__button-mobile`}
                  />
                ))}
              </div>
            )}
            {integrationsList.map((integration) => (
              <integration.body
                key={`${integration.company_name}__body-mobile`}
              />
            ))}
            <IntegrationBanners />
          </div>
        )}
      </div>
    </SellerInnerPageTemplate>
  );
}

const IntegrationBanners = () => {
  return (
    <div className={styles.integrationBanners}>
      <div className={classNames(styles.banner, styles.integrationBanner)}>
        <img
          src={MagnifierGlassSVG}
          width="48"
          height="48"
          alt=""
          className={styles.magnifierImage}
        />
        <h3 className={styles.bannerTitle}>
          Can't find what you're looking for?
        </h3>
        <p className={styles.bannerBody}>
          Let us know what integration you'd like to see next.
        </p>
        <div className={styles.bannerLink}>
          <a
            href="https://forms.gle/ut8sVsFTRuTWWpbR9"
            target="_blank"
            className={styles.link}
            rel="noreferrer"
          >
            Request a new integration
          </a>
          <img src={ArrowSVG} width="20" height="20" alt="" />
        </div>
      </div>
      <div className={classNames(styles.banner, styles.apiBanner)}>
        <img
          src={RainbowSVG}
          width="48"
          height="48"
          alt=""
          className={styles.rainbowImage}
        />
        <h3 className={styles.bannerTitle}>Want something bespoke?</h3>
        <p className={styles.bannerBody}>
          If you’re looking for an automated lending solution for customers on
          your online store, we have an API.
        </p>
        <div className={styles.bannerLink}>
          <a
            href="https://coda.io/d/Ecommerce-API_dQD3cMju607/iwocaPay-E-Commerce-API-V1-1_su3iY"
            className={styles.link}
            target="_blank"
            rel="noreferrer"
          >
            See our API docs
          </a>
          <img src={ArrowSVG} width="20" height="20" alt="" />
        </div>
      </div>
    </div>
  );
};
